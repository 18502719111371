/**
 * Calculate price for given products
 */
export function calculator(acc) {
  return acc.reduce((total, product) => {
    /**
     * STICK TO PENNYLANE PRICES | TRUST THE PROCESS
     * - convert price into euro value (price/100)
     * - round price to two digits (price.toFixed(2))
     * - convert price into cents (price*100)
     */
    const productVAT = +(product.price * product.vat * product.quantity / 100).toFixed(2) * 100;
    return total + productVAT;
  }, 0);
}
