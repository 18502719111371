export default {
  SET_MORE_ARTICLES(state, payload) {
    payload.forEach((article) => {
      state.articles.push(article)
    })
  },

  SET_HEADLINES(state, payload) {
    payload.forEach((headline) => {
      // add headline if it's not already in headlines list
      const existingHeadline = state.headlines.find((eh) => eh.id === headline.id)
      if (!existingHeadline) state.headlines.push(headline);
    })
  }
}
