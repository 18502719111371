import { OrderingRepository } from './repository'

export default {
  async GET_ORDERS({ commit, rootState }) {
    const orders = await OrderingRepository.getOrders(rootState.auth.user.mainShopId)
    if (orders) orders.sort((a, b) => b.updatedAt - a.updatedAt)
    commit('SET_ORDERS', orders)
  },

  async GET_ORDER({ commit, rootState }, orderId) {
    const order = await OrderingRepository.getOrder(orderId)
    commit('SET_ORDERDETAILSBYID', order)
    return order
  },

  async GET_SHIPPING_MODES({ commit }) {
    const shippingModes = await OrderingRepository.getShippingModes()
    commit('SET_SHIPPING_MODES', shippingModes)
  },

  async SEND_ORDER({ state, rootState, commit, getters, rootGetters }) {
    // Build the order object to send API
    try {
      const { shopId, shippingModeId, paymentModeId } = state.orderingInfos
      const userId = rootState.auth.userId
      const basketId = rootState.products.lastBasketId
      const basketKey = await OrderingRepository.getBasketKey(basketId);
      const cartDiscoverOffer = rootGetters['offers/getSelectedOffer']

      const couponCodes = rootGetters['coupons/couponsInCart'].map(coupon => coupon.code)
      const { shipmentInstructions, requestedFor } = rootGetters['ordering/orderingOptions']

      let order = {
        shopId,
        userId,
        shippingModeId,
        paymentModeId,
        basketKey,
        couponCodes,
        shipmentInstructions,
        reference: 'orderFromApp'
      }
      if (requestedFor) order.requestedFor = requestedFor
      if (cartDiscoverOffer) order.discoverOffer = cartDiscoverOffer
      return await OrderingRepository.sendOrder(order)
    } catch (err) {
      throw err
    }
  },

  async CHECK_ORDER_CREATION({}, queueId) {
    let orderStatus
    do {
      orderStatus = await OrderingRepository.getQueueOrderStatus(queueId)
      await new Promise(resolve => setTimeout(resolve, 2000))
    } while (orderStatus.status !== 'validated' && orderStatus.status !== 'failed')

    return { status: orderStatus.status, statusCode: orderStatus.statusCode }
  },

  async GET_BILL_BY_ORDER_ID({}, payload) {
    const { id, reference } = payload
    try {
      const bill = await OrderingRepository.getBillByOrderId(id);
      const newWindow = window.open();
      newWindow.document.write(bill);
    } catch (err) {
      throw err
    }
  }
}
