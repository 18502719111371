import { ShopRepository } from './repository'

export default {
  /**
   * Get current shop
   */
  async LOAD_SHOP ({ commit, state }, shopId) {
    const shop = await ShopRepository.loadShop(shopId)
    commit('SET_SHOP', shop)
  },

  /**
   * save current shop
   */
  async SAVE_SHOP ({ commit, state }) {
    const { shop } = state
    return ShopRepository.saveShop(shop)
  },

  /**
   * Get current shop user
   */
  async LOAD_SHOP_USER ({ commit, state }, userId) {
    const { shop } = state
    const user = await ShopRepository.loadShopUser(userId, shop.id)

    commit('SET_USER', user)
  },

  /**
   * save current shop user
   */
  async SAVE_SHOP_USER ({ state }) {
    const { shopUser } = state
    return ShopRepository.saveShopUser(shopUser)
  },
  // async SAVE_SHOP_USER ({ commit, state }) {
  //   const { shopUser } = state
  //   const user = await ShopRepository.saveShopUser(shopUser)
  //   commit('SET_USER', user)
  //   return user
  // }

  /**
   * recover users from given shop
   */
  async GET_USERS_SHOP ({ state }) {
    return state.shop ? await ShopRepository.loadUsersShop(state.shop.id) : null;
  }
}
