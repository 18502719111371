import dayjs from 'dayjs'

export default {
  checkIfCouponsExist: state => id => {
    return !!(state.coupons.find(c => c.id == id))
  },
  couponsPurchable: state => {
    const { coupons } = state
    return coupons.filter(c => c.isPurchasable).sort((a, b) => {
      return new Date(b.createdAt) -  new Date(a.createdAt)
    })
  },
  couponsPurchased: state => {
    const { coupons, purchasedCoupons } = state
    const finalCoupons = []
    coupons.forEach(c => {
      const tmp = purchasedCoupons.find(p => p.couponId == c.id)
      if (tmp) {
        finalCoupons.push({
          ...c,
          quantity: tmp.quantity,
          expiredAt: tmp.expirationDate
        })
      }
    })
    return finalCoupons
  },
  couponsUsable: state => {
    const { coupons, purchasedCoupons } = state
    /*
    const finalCoupons = [
      ...coupons.filter(
        c =>
          !c.isPurchasable &&
          (dayjs(c.expiredAt)
            .fromNow()
            .indexOf('ago') == -1 ||
            !c.expiredAt) &&
          c.type?.indexOf('Colisage') == -1
      )
    ]
    */
    let finalCoupons = []
    coupons.forEach(c => {
      const tmp = purchasedCoupons.find(p => p.couponId == c.id)
      if (tmp) {
        finalCoupons.push(c)
      }
    })
    return finalCoupons
  },
  couponsColisage: state => {
    const { coupons } = state
    if (coupons) return coupons.filter(coupon => {
      return coupon.type?.indexOf('Colisage') !== -1
    })
    return coupons
  }
}
