import clone from 'clone'
import { AuthRepository } from './repository'

export default {
  /**
   * Login action
   * @param {*} param0
   * @param {*} payload
   */
  async LOGIN({ commit }, payload) {
    const { email, password, remember, lastRefresh } = payload
    try {
      const user = await AuthRepository.login(email, password, remember)
      commit('SET_USER', user)
      commit('SET_ACCESS_TOKEN', user.accessToken)
      commit('SET_REFRESH_TOKEN', user.refreshToken)
      commit('SET_LAST_REFRESH', user.lastRefresh)
      if (user.expiry) commit('SET_EXPIRY', user.expiry)
    } catch (err) {
      throw err
    }
  },

  /**
   *  Register action
   *
   */

  async REGISTER({ commit, state }) {
    const { user, shop } = state
    const streetName = shop.addressLine1.replace(/\d+/g, '').trim()
    // shop.bornAt = new Date(shop.bornAt)
    const userWithShop = { ...user, ...shop }
    console.log(userWithShop)
    try {
      return await AuthRepository.register(userWithShop)
    } catch (err) {
      throw err
    }
  },

  /**
   * Logout action
   */
  async LOGOUT({ commit }) {
    await AuthRepository.logout()
    commit('SET_USER', null)
    commit('SET_USER_ID', null)
  },

  /**
   *  Reset password email
   */
  async SEND_RESET_PASSWORD_EMAIL({}, payload) {
    await AuthRepository.sendResetPasswordEmail(payload)
  },

  /**
   *  Reset password asking action
   */
  async RESET_PASSWORD({}, payload) {
    await AuthRepository.resetPassword(payload)
  },

  /**
   *  Change password asking action
   */
  async CHANGE_PASSWORD({}, payload) {
    await AuthRepository.changePassword(payload)
  },

  /**
   * Get profile user action
   */
  async LOAD_PROFILE({ commit, dispatch, state }) {
    const userId = state.userId
    try {
      const returnedUser = await AuthRepository.getProfile(userId)
      commit('SET_USER', returnedUser)
      return returnedUser
    } catch (err) {
      console.error(err)
      dispatch('LOGOUT')
    }
  },

  /**
   * Save profile user action
   */
  async SAVE_PROFILE({ state }) {
    const user = clone(state.user)
    await AuthRepository.saveProfile(user)
  },

  /**
   * Load salesman
   */
  async LOAD_SALESMAN({ commit, state }) {
    const { user } = state

    if (!user?.mainShop?.salesmanId) return
    const salesman = await AuthRepository.getSalesmanInfos(user.mainShop.salesmanId)
    commit('SET_SALESMAN', salesman)
  },

  /**
   * Get payment modes
   */
  async GET_PAYMENT_MODES({ commit }, shopId) {
    try {
      const paymentModes = await AuthRepository.getPaymentModes(shopId)
      if (paymentModes) commit('SET_PAYMENT_MODES', paymentModes)
    } catch (err) {
      console.error(err, 'GETTED ERROR')
      return err
    }
  },

  /**
   * Save paymentMode
   */
  async SAVE_PAYMENT_MODE({}, payload) {
    await AuthRepository.savePaymentMode(payload)
  },

  /**
   * Delete paymentMode
   */
  async DELETE_PAYMENT_MODE({}, payload) {
    await AuthRepository.deletePaymentMode(payload)
  },

  /**
   * Create love
   */
  async CREATE_LOVE({ state }, payload) {
    const { userId } = state
    if (payload.productId) await AuthRepository.createProductLove({ ...payload, userId })
    else if (payload.goodyId) await AuthRepository.createGoodyLove({ ...payload, userId })
    else if (payload.blogPostId) await AuthRepository.createPostLove({ ...payload, userId })
  },

  /**
   * Delete love
   */
  async DELETE_LOVE({}, payload) {
    if (payload.productId) await AuthRepository.deleteProductLove(payload.id)
    else if (payload.goodyId) await AuthRepository.deleteGoodyLove(payload.id)
    else if (payload.blogPostId) await AuthRepository.deletePostLove(payload.id)
  },
  /**
   * Get loves article from auth user
   */
  async LOAD_LOVES({ commit, state }, type) {
    const { userId } = state
    if (type == 'product') {
      const pLoves = await AuthRepository.getProductLoves({ type, userId })
      commit('SET_PRODUCT_LOVES', pLoves)
    } else if (type == 'goody') {
      const gLoves = await AuthRepository.getProductLoves({ type, userId })
      commit('SET_GOODY_LOVES', gLoves)
    } else if (type == 'pressArticle') {
      const aLoves = await AuthRepository.getPostLoves(userId)
      commit('SET_PRESS_ARTICLE_LOVES', aLoves)
    }
  },

  /**
   * Get loves from auth user
   */
  async LOAD_PRESS_ARTICLE_LOVES({ commit, state }) {
    const { userId } = state
    const loves = await AuthRepository.getPostLoves(userId)
    commit('SET_PRESS_ARTICLE_LOVES', loves)
  },

  async ASK_GDPR() {
    await AuthRepository.getGdpr()
  }
}
