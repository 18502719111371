export default {
  noData: 'Aucun résultat',
  noDataHint: `Ce jeu n'existe peut-être pas encore, mais patience...\n L'idée est peut-être en train de germer dans le cerveau d'un.e auteur.rice !`,
  endOfResult: 'Fin des résultats.',
  all: 'Tous les jeux',
  allXs: 'Les jeux',
  carts: 'Panier types',
  goodies: 'Goodies/PLV',
  points: 'Cubes',
  favorite: 'Mes coups de',
  favoriteXs: 'Coups de',
  goodPlans: 'Bons plans',
  exportProduct: 'Exporter les produits',
  publishedAt: 'Publié le',
  noResultsDetails: `Ce jeu n'existe peut-être pas encore, mais patience... L'idée est peut-être en train de germer dans le cerveau de l'un de nos auteurs.`,
  noResults: {
    default: {
      noData: 'Aucun résultat',
      noDataHint: `Ce jeu n'existe peut-être pas encore, mais patience...\n L'idée est peut-être en train de germer dans le cerveau de l'un de nos auteurs.`
    },
    likes: {
      noData: 'Aucun résultat',
      noDataHint:
        'Vous n’avez pas encore marqué d’article en favori. Cliquez sur son <i class="mdi mdi-heart"></i> pour que le produit apparaisse ici !'
    },
    precommande: {
      noData: 'Aucun article en précommande'
    },
    promotion: {
      noData: 'Aucun article en bon plan',
      noDataHint: ''
    }
  }
}
