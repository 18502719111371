import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'
export class OrderingRepository {
  static async sendOrder(payload) {
    try {
      const order = { ...payload }
      return await $http.post('/orders/create', order)
    } catch (err) {
      console.error(err, 'ERROR')
      throw err
    }
  }

  static async getQueueOrderStatus(queueId) {
    return await $http.get(`/ordersqueues/${queueId}`)
  }

  static async getOrders(shopId) {
    return await $http.get(`/shops/${shopId}/orders`)
  }

  static async getOrder(id) {
    const url = Querier.query({ baseUrl: `/orders/${id}` })
      .populate('goodyOrders')
      .populate('productOrders')
      .populate('orderDiscounts')
      .populate('shippingMode')
      .generate()
    return await $http.get(url)
  }

  static async getBasketKey(id) {
    return (await $http.get(`/baskets/${id}`)).key;
  }

  static async getShippingModes() {
    return await $http.get('/shippingmodes')
  }

  static async getBillByOrderId(orderId) {
    try {
      return await $http.get(`/orders/${orderId}/download`);
    } catch (err) {
      throw err
    }
  }
}
