export class TutorialRepository {
  static setScriptTutorialDone(script) {
    localStorage.setItem('scriptsComplete', JSON.stringify(script))
  }
  static tutorialAbort() {
    localStorage.setItem('disableTutorial', true)
  }
  static getDisableTutorial() {
    return localStorage.getItem('disableTutorial') == 'true'
  }

  static getScriptsDone() {
    if (
      localStorage.getItem('scriptsComplete') &&
      localStorage.getItem('scriptsComplete') !== '[object Object]'
    )
      return JSON.parse(localStorage.getItem('scriptsComplete'))
    return null
  }
  static getScriptDone(scriptName) {
    const scriptsDone =  this.getScriptsDone()
    if (scriptsDone)
      return Object.keys(scriptsDone).includes(scriptName)
    return false
  }
  static tutorialReset() {
    localStorage.setItem('disableTutorial', false)
    localStorage.setItem('scriptsComplete', {})
  }
}
