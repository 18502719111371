export default {
  cart: [],
  deletedFromCart: [],
  quantityUpdatedFromCart: [],
  lastBasketId: null,

  hasBasketChanged: false,

  products: [],
  goodies: [],

  totalProducts: 0,
  AllProductsCollect: false,
  categories: [],

  autoCompletionOptions: [],

  productsAddedToCart: null,
  product: null,

  currentQuizChoices: [],

  display: {
    inLine: false
  },

  pagination: {
    limit: 20,
    offset: 0
  },

  // SAV part required to have a product listing, a new object is necessary and a new pagination in order to not alter the classic products listing
  productsSav: [],

  totalProductsSav: 0,

  paginationSav: {
    limit: 10,
    offset: 0
  },

  pricingList: {},
  shoppedItems: {}
}
