import order from './order'
export default {
  order,
  headers: {
    address: 'Adresse de livraison',
    deliveryWay: 'Moyen de livraison',
    paymentWay: 'Moyen de paiement',
    orderForm: 'Bon de commande',
    confirmation: 'Commande envoyée'
  },
  currentOrders: {
    reliquate: 'Mes reliquats',
    current: 'Commandes en cours',
    past: 'Commandes passées',
    afterSale: 'Service après-vente',
    cancelled: 'Annulées'
  },
  actions: {
    goBackToCart: 'Retour au panier',
    addPaymentModeBtn: 'Ajouter un moyen de paiement',
    noPaymentBtn: 'choisir un moyen de paiement',
    validationAddress: 'Valider mon adresse de livraison',
    validationDeliveryWay: 'Valider le mode de livraison',
    validationPayment: 'Valider le paiement',
    validationOrderFrom: 'Confirmer ces informations',

    changeAddress: `Changer d'adresse de livraison`,
    changeDelivery: `Changer de mode de livraison`,
    changePayment: `Changer de moyen de paiement`,

    confirmOrder: `Confirmer ces informations et passer commande`,

    editPayments: `Mes moyens de paiements`,
    hideSAV: `Masquer les SAV`,

    home: `Retour`
  },
  myShop: 'Ma boutique',
  otherShops: 'Mes autres boutiques',

  delivery: 'Livraison',
  standardDelivery: 'Livraison Standard',
  expressDelivery: 'Livraison Express',

  paymentModes: 'Mes moyens de paiement',
  paymentPrefix: 'Paiement par',

  deliveryOption: 'Option de livraison',
  deliveryDate: { label: "Date d'expédition souhaitée", error: 'Ce champ est incorrect' },
  deliveryNote: { label: 'Instruction de livraison', error: 'Ce champ est incorrect' },

  paymentModeSelectionError:
    'Une erreur est survenue lors de la récupération de vos modes de paiement. Merci de contacter le support par email à aide@tribuo.fr .',
  bankTransfer: 'Virement bancaire',
  bankDirectDebit: 'Prélèvement',
  billOfExchange: 'Traiter auto LCR',
  card: 'Carte bancaire',

  paymentTitle: 'Veuillez choisir votre moyen de paiement',
  noPayment: "Aucun moyen de paiement n'est rattaché à la commande.",
  noPayment2: 'Veuillez choisir votre moyen de paiement.',

  nocurrent: "Vous n'avez aucune commande en cours pour le moment.",
  noreliquate: "Vous n'avez aucun reliquat de commande.",
  nopast: "Vous n'avez aucune commande passée pour le moment.",
  noafterSale: "Vous n'avez aucune commande liée au service après-vente pour le moment.",

  bankTransferInformations: 'Effectuez votre virement à:',
  iban: 'Iban',
  rib: 'RIB',
  bic: 'Code bic',

  orderContent: 'Contenu de ma commande',

  confirmation: {
    greetings: 'Merci pour votre achat !',
    sent: `Votre commande est confirmée. Vous pouvez suivre l'avancée dans votre profil, dans le menu "Commandes".`,
    follow: `Vous pouvez suivre l'avancée dans votre profil, dans le menu "Commandes"`,
    notifications: `Activer les notifications`,
    question: `Pour toute question, contactez nous par mail contact@tribuo.fr`
  },

  errors: {
    E00059: 'Les offres commerciales appliquées ne sont pas compatibles avec votre panier.',
    E00035: `La facture n'a pas pu être générée. Merci de réessayer ou de contacter votre commercial.`,
    E00036: `Coupon expiré. Merci de réessayer avec un autre coupon.`,
    E00048: `Vous n'avez pas assez de cubes. Merci de vérifier votre panier.`,
    E00040: `Une erreur est survenue. Merci de contacter votre commercial ou d'envoyer un email à l'adresse aide@tribuo.fr`,
    E00041: `Montant minimum non atteint. Merci de contacter votre commercial ou d'envoyer un email à l'adresse aide@tribuo.fr`,
    E00042: `Une erreur est survenue. Merci de contacter votre commercial ou d'envoyer un email à l'adresse aide@tribuo.fr`,
    E00043: `La quantité de l'un des produits commandé excède la limite hebdomadaire.`,
    E00069: `Aucune commande ne peut être envoyé avec ce compte. Veuillez-nous contacter à aide@tribuo.fr`,
    E00070: `La quantité d'un produit est invalide, veuillez vérifier votre commande. Si le problème persiste, veuillez-nous contacter à aide@tribuo.fr`,
    E00082: `Une erreur est survenue, veuillez réessayer dans quelques instants. Si le problème persiste, veuillez-nous contacter à aide@tribuo.fr`,
    E00000: 'Une erreur est survenue, veuillez réessayer dans quelques instants. Si le problème persiste, veuillez-nous contacter à aide@tribuo.fr'
  }
}
