<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script>
import * as Sentry from '@sentry/browser'
import { get } from 'vuex-pathify'
import tarteaucitronscript from './utils/tarteaucitron'

export default {
  name: 'App',

  data() {
    return {
      intervalSaveBasket: null
    }
  },

  computed: {
    userId: get('auth/userId'),
    currentRoute() {
      return this.$route.name
    }
  },

  watch: {
    currentRoute: {
      handler(val) {
        if (val === 'login') this.userLoaded = false
        if (val === 'ordering-confirmation') clearInterval(this.intervalSaveBasket)
      },
      immediate: true
    },
    userId: {
      async handler(val) {
        if (!val) return
        if (this.intervalSaveBasket) clearInterval(this.intervalSaveBasket)
        const userInfos = await this.$store.dispatch('auth/LOAD_PROFILE')
        Sentry.setUser({ id: val, email: userInfos.email })
        //If is not validated, redirect to pending page
        const userIsValidated = userInfos?.isValidated
        const shopIsValidated = userInfos?.mainShop?.isValidated
        if ((!userIsValidated || !shopIsValidated) && this.$route.name !== 'pendingAccount')
          this.$router.push({ name: 'pendingAccount' })
        else if (userIsValidated && shopIsValidated && this.$route.name === 'pendingAccount')
          this.$router.push('/')

        if (userIsValidated && shopIsValidated) {

          await this.loadShopRequiredDatas()

          const saveBasket = () => {
            try {
              if (this.$store.state.products.cart.length)
                this.$store.dispatch('products/SAVE_BASKET')
            } catch {
              clearInterval(this.intervalSaveBasket)
            }
          }

          // Save basket
          this.intervalSaveBasket = setInterval(saveBasket, 30 * 1000)
        }
      },
      immediate: true
    }
  },
  methods: {
    async loadShopRequiredDatas() {
      this.$q.loading.show()
      try {
        await this.$store.dispatch('products/LOAD_CATEGORIES')

        // Load already purchased item this week
        await this.$store.dispatch('products/LOAD_SHOPPED_ITEMS')

        // Loading coupons & gamifications stats
        await this.$store.dispatch('gamification/GET_POINT_ENTRIES')
        await this.$store.dispatch('coupons/GET_COUPONS')

        // Loading shipping fees infos
        await this.$store.dispatch('ordering/GET_SHIPPING_MODES')

        // We're loading baskets info
        await this.$store.dispatch('products/LOAD_LAST_BASKET')
        this.shopDatasLoaded = true
      } catch (err) {
        console.error('ERROR CAUSING LOGOUT ' + err)
        // this.$store.dispatch('auth/LOGOUT')
        this.shopDatasLoaded = true
      }
      this.$q.loading.hide()
    }
  },
  beforeCreate() {
    this.$store.dispatch('tutorial/MOVETOSTORE')
  },
  mounted() {
    tarteaucitronscript()
    if (process.env.ENV === 'prod') {
      tarteaucitron.user.zohoPageSenseProjectId = 'tribuo'
      tarteaucitron.user.zohoPageSenseScriptHash = 'f634f972264c42db98b101dee8f43b9c'
      ;(tarteaucitron.job = tarteaucitron.job || []).push('zohopagesense')
    }
  }
}
</script>
