export default {
  formattedHeadlines: state => {
    const { headlines } = state
    const imageNotFoundLink = "https://lightwidget.com/wp-content/uploads/localhost-file-not-found.jpg";

    const returnedHeadlines = headlines.map(headline => {
      if (headline.label)
        return {
          title: headline.name,
          type: 'product',
          id: headline.id,
          picture: headline?.pictures?.length ? headline.pictures[0].link : null,
          publishedAt: headline.publishedAt,
          label: headline.label
        }
      const image = headline.banner
        ? headline.banner.linkLarge
          ? headline.banner.linkLarge
          : imageNotFoundLink
        : imageNotFoundLink;
      return {
        title: headline.title,
        type: 'news',
        id: headline.id,
        publishedAt: headline.publishedAt,
        picture: image
      }
    })

    return returnedHeadlines
  },
  articlesFavorite: (state, getters, rootState) => {
    if (state.display.favorite)
      return state.articles.filter(a =>
        rootState.auth.pressArticleLoves.map(({ blogPostId }) => blogPostId).includes(a.id)
      )
    return state.articles
  }
}
