import axios from 'axios'
import { dayjs } from './other'
import tokenProvider from 'axios-token-interceptor'

const baseURL = process.env.API_BASE_URL || 'http://localhost:3000'

const $http = axios.create({
  baseURL
})

const $httpWithoutRefresh = axios.create({
  baseURL
})

$http.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      // debug error
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // No answer
      console.log(error.request)
    } else {
      console.log('Error', error.message)
    }
    throw error
  }
)

$httpWithoutRefresh.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      // debug error
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // No answer
      console.log(error.request)
    } else {
      console.log('Error', error.message)
    }

    throw error
  }
)

export default ({ store, redirect }) => {
  const refreshTokenFromAuthServer = async () => {
    const refreshToken = localStorage.getItem('refreshToken') || store.getters['auth/refreshToken']
    const accessTokenExpiry = localStorage.getItem('expiry') || store.getters['auth/expiry']
    const lastRefresh = localStorage.getItem('lastRefresh') || store.getters['auth/lastRefresh']
    const currentAccessToken =
      localStorage.getItem('accessToken') || store.getters['auth/accessToken']
    if (!refreshToken) return

    const payload = {
      token: refreshToken
    }

    try {
      if (!accessTokenExpiry || dayjs().isAfter(accessTokenExpiry)) {
        store.dispatch('auth/LOGOUT')
        redirect({ path: '/auth/login' })
        return
      } else if (dayjs().isAfter(dayjs(lastRefresh).add({ hours: 6 }))) {
        console.log(lastRefresh, dayjs().isAfter(lastRefresh), accessTokenExpiry)
        const { accessToken, refreshToken } = await axios
          .post(`${baseURL}/auth/refresh`, payload)
          .then(response => response.data)

        localStorage.setItem('accessToken', accessToken)
        store.commit('auth/SET_ACCESS_TOKEN', accessToken)
        store.commit('auth/SET_REFRESH_TOKEN', refreshToken)
        store.commit('auth/SET_LAST_REFRESH', dayjs())
        return accessToken
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        console.log('Not connected...')
      } else {
        console.error(error)
      }

      try {
        await store.dispatch('auth/LOGOUT')
      } catch (err) {
        console.error('Logout done')
      }

      // redirect({ path: '/auth/login' })
      return
    }
    if (currentAccessToken) return currentAccessToken
  }

  const getToken = tokenProvider.tokenCache(refreshTokenFromAuthServer, { maxAge: 15000 })

  $http.interceptors.request.use(
    tokenProvider({
      getToken
    })
  )

  // const { type } = decodeToken(refreshToken)
}
export { $http, $httpWithoutRefresh }
