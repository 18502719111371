import { offersRepository } from './repository'

export default {
  async GET_DISCOVERY_OFFERS({ rootState, commit }) {
    const offers = await offersRepository.getDiscoveryOffers({
      shopId: rootState.auth.user.mainShop.id
    })
    commit('SET_DISCOVERY_OFFERS', offers)
  }
}
